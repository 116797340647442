var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"q-view-customer"},[_c('q-page',{attrs:{"schema":{
				xType: 'q-page',
				title: _vm.title,
				items: [
					{
						id: "q-version-form",
						labelWidth: '150px',
						disabled: _vm.$route.query.mode === 'view',
						xType: 'q-form',
						items: [
							{
								xType: 'q-field',
								fType: 'q-input',
								name: '版本名称',
								label: '版本名称',
                max: 20,
								prop: 'name',
								rules: [
									{
										required: true,
										message: '版本名称不能为空',
										trigger: 'blur',
									} ],
							},
							{
								xType: 'q-field',
								fType: 'q-select',
								remoteOnce: _vm.applicationApi.getAll,
								onSelectChange: function (val) {
									this$1.selectChange(val);
								},
								onValueChange: function (val) {
									this$1.valueChange(val);
								},
								name: '选择应用',
								label: '选择应用',
								placeholder: '选择应用',
								prop: 'appId',
								rules: [
									{
										required: true,
										message: '请选择应用',
										trigger: 'blur',
									} ],
							},
							{
								xType: 'q-field',
								fType: 'q-input-number',
								name: '项目数量上限',
								label: '项目数量上限',
								rawType: 'number',
								hidden: _vm.hiddenApplication,
								prop: 'projectLimit',
								rules: [
									{
										required: true,
										message: '请输入项目数量上限',
										trigger: 'blur',
									} ],
							},
							{
								xType: 'q-field',
								fType: 'q-input-number',
								name: '盒子数量上限',
								label: '盒子数量上限',
								rawType: 'number',
								hidden: _vm.hiddenApplication,
								prop: 'deviceLimit',
								rules: [
									{
										required: true,
										message: '请输入盒子数量上限',
										trigger: 'blur',
									} ],
							},
							{
								xType: 'q-field',
								fType: 'q-input-number',
								name: '员工数量上限',
								label: '员工数量上限',
								hidden: _vm.hiddenApplication,
								rawType: 'number',
								prop: 'employeeLimit',
								rules: [
									{
										required: true,
										message: '请输入员工数量上限',
										trigger: 'blur',
									} ],
							},
							{
								xType: 'q-field',
								fType: 'q-input-number',
								name: '流量上限',
								label: '流量上限',
								hidden: _vm.hiddenApplication,
								rawType: 'number',
								prop: 'flowLimit',
							},
							{
								xType: 'q-field',
								fType: 'q-checkbox-group',
								id: 'version-resources',
								name: '拥有权限',
								label: '拥有权限',
								prop: 'resources',
								hidden: _vm.versionResources.hidden,
								opts: _vm.versionResources.opts,
								rules: [
									{
										required: !_vm.hiddenApplication,
										message: '拥有权限不能为空',
										trigger: 'blur',
									} ],
							} ],
					},
					{
						xType: 'q-button-panel',
						items: [
							{
								xType: 'q-button',
								text: '确定',
								type: 'primary',
								visible: _vm.$route.query.mode !== 'view',
								click: function (event) {
									this$1.ok();
								},
							},
							{
								xType: 'q-button',
								text: '取消',
								click: function () {
									this$1.$back();
								},
							} ],
					} ],
			},"path":"/"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }