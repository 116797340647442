<!--  -->
<template>
	<div class="q-view-customer">
		<q-page
			:schema="{
				xType: 'q-page',
				title: title,
				items: [
					{
						id: `q-version-form`,
						labelWidth: '150px',
						disabled: $route.query.mode === 'view',
						xType: 'q-form',
						items: [
							{
								xType: 'q-field',
								fType: 'q-input',
								name: '版本名称',
								label: '版本名称',
                max: 20,
								prop: 'name',
								rules: [
									{
										required: true,
										message: '版本名称不能为空',
										trigger: 'blur',
									},
								],
							},
							{
								xType: 'q-field',
								fType: 'q-select',
								remoteOnce: applicationApi.getAll,
								onSelectChange: (val) => {
									this.selectChange(val);
								},
								onValueChange: (val) => {
									this.valueChange(val);
								},
								name: '选择应用',
								label: '选择应用',
								placeholder: '选择应用',
								prop: 'appId',
								rules: [
									{
										required: true,
										message: '请选择应用',
										trigger: 'blur',
									},
								],
							},
							{
								xType: 'q-field',
								fType: 'q-input-number',
								name: '项目数量上限',
								label: '项目数量上限',
								rawType: 'number',
								hidden: hiddenApplication,
								prop: 'projectLimit',
								rules: [
									{
										required: true,
										message: '请输入项目数量上限',
										trigger: 'blur',
									},
								],
							},
							{
								xType: 'q-field',
								fType: 'q-input-number',
								name: '盒子数量上限',
								label: '盒子数量上限',
								rawType: 'number',
								hidden: hiddenApplication,
								prop: 'deviceLimit',
								rules: [
									{
										required: true,
										message: '请输入盒子数量上限',
										trigger: 'blur',
									},
								],
							},
							{
								xType: 'q-field',
								fType: 'q-input-number',
								name: '员工数量上限',
								label: '员工数量上限',
								hidden: hiddenApplication,
								rawType: 'number',
								prop: 'employeeLimit',
								rules: [
									{
										required: true,
										message: '请输入员工数量上限',
										trigger: 'blur',
									},
								],
							},
							{
								xType: 'q-field',
								fType: 'q-input-number',
								name: '流量上限',
								label: '流量上限',
								hidden: hiddenApplication,
								rawType: 'number',
								prop: 'flowLimit',
							},
							{
								xType: 'q-field',
								fType: 'q-checkbox-group',
								id: 'version-resources',
								name: '拥有权限',
								label: '拥有权限',
								prop: 'resources',
								hidden: versionResources.hidden,
								opts: versionResources.opts,
								rules: [
									{
										required: !hiddenApplication,
										message: '拥有权限不能为空',
										trigger: 'blur',
									},
								],
							},
						],
					},
					{
						xType: 'q-button-panel',
						items: [
							{
								xType: 'q-button',
								text: '确定',
								type: 'primary',
								visible: $route.query.mode !== 'view',
								click: (event) => {
									this.ok();
								},
							},
							{
								xType: 'q-button',
								text: '取消',
								click: () => {
									this.$back();
								},
							},
						],
					},
				],
			}"
			path="/"
		></q-page>
	</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import api from '@/api/appVersion';
import applicationApi from '@/api/application';
import { findComponentById } from '@/utils/index';
import BizCode from '@/utils/BizCode';

export default {
	name: 'QEditVersion',
	//import引入的组件需要注入到对象中才能使用
	components: {},
	data() {
		//这里存放数据
		return {
			versionResources: {
				hidden: true,
				opts: [],
			},
			hiddenApplication: false, // 展示
			title: '新增版本',
			applicationApi: applicationApi,
		};
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {},
	//生命周期 - 挂载完成（可以访问DOM元素）
	async mounted() {
		this.title = this.$route.query.mode === 'view' ? '查看版本' : this.$route.query.mode === 'add' ? '新增版本' : '修改版本';
		if (this.$route.query.mode === 'view' || this.$route.query.mode === 'update') {
			const result = await api.detail(this.$route.query.id);
			console.log(result,"详情接口");
			if (result.code === BizCode.OK) {
				const frm = findComponentById('q-version-form', this);
				const { data } = result;
				// eslint-disable-next-line no-unused-vars
				const { id, name, appId, params, ...fields } = data;
				const { projectLimit, deviceLimit, employeeLimit, flowLimit, resources } = params;
				frm.setData({
					id: id,
					name: name,
					appId: appId,
					projectLimit,
					deviceLimit,
					employeeLimit,
					flowLimit,
					resources,
				});
			}
		}
	},
	//方法集合
	methods: {
		async ok() {
			const frm = findComponentById('q-version-form', this);
			frm.validate(async (valid) => {
				//验证成功
				if (valid) {
					let result = null;
					const fields = frm.getData();
					const {
						name,
						appId,
						projectLimit,
						deviceLimit,
						employeeLimit,
						flowLimit,
						resources,
						// eslint-disable-next-line no-unused-vars
						...others
					} = fields;
					if (this.$route.query.mode === 'add') {
						result = await api.add({
							name,
							appId,
							params: {
								projectLimit,
								deviceLimit,
								employeeLimit,
								flowLimit,
								resources,
							},
						});
					} else {
						result = await api.update(this.$route.query.id, {
							name,
							appId,
							params: {
								projectLimit,
								deviceLimit,
								employeeLimit,
								flowLimit,
								resources,
							},
						});
					}
					if (result.code === BizCode.OK) {
						this.$back();
					}
				}
			});
		},
		// 选项改变时触发首次加载时不触发
		// eslint-disable-next-line no-unused-vars
		selectChange(val) {
			//若清空选择则清空选项
			const versionResources = findComponentById('version-resources', this);
			if (versionResources) {
				versionResources.clearValidate();
				versionResources.setData((data) => {
					if (data !== undefined && data !== null) {
						data.splice(0, data.length);
					}
				});
			}
		},
		// 选项改变时触发首次加载时都触发
		async valueChange(val) {
			const frm = findComponentById('q-version-form', this);
			console.log(frm,'frm');
			console.log(frm.schema);
      console.log(val);
			if (val === undefined || val === null || val === '') {
				this.versionResources.opts = [];
				this.versionResources.hidden = true;
			} else if (val === 'qdzx' || val === 'qyyt') {
				this.versionResources.opts = [];
				this.versionResources.hidden = true;
        this.hiddenApplication = true
			} else {
				this.versionResources.hidden = false;
        this.hiddenApplication = false
				const opts = await applicationApi.getResources(val);
				this.versionResources.opts = opts;
			}
		},
	},
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
</style>
