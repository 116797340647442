
import { request } from "@/utils/request";
import { loading } from "@/utils/index";

const BASE_PATH = "/appVersion";

//版本
class Api {
  //新增
  @loading()
  async add(form) {
    return request.post(`${BASE_PATH}`, form);
  }

  //修改
  @loading()
  async update(id, form) {
    return request.put(`${BASE_PATH}/${id}`, form);
  }

  /** 版本详情 */
  @loading({ delay: 0 })
  async detail(id) {
    return request.get(`${BASE_PATH}/${id}`);
  }

  /** 删除版本 */
  @loading()
  async del(id) {
    return request.delete(`${BASE_PATH}/${id}`);
  }

  /** 分页获取版本列表 */
  @loading({ delay: 0 })
  async getPageList(params) {
    return request.get(`${BASE_PATH}/page`, { params: params });
  }

  @loading({ delay: 500 })
  async listAll(){
    const result = await request.get(`${BASE_PATH}/listAll`);
    return result;
  }


}

export default new Api();
