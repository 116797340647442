import { request } from "@/utils/request";
import { loading } from "@/utils/index";
import BizCode from "@/utils/BizCode";

const BASE_PATH = "/application";

//应用
class Api {
  //新增
  @loading()
  async add(form) {
    return request.post(`${BASE_PATH}`, form);
  }

  //修改
  @loading()
  async update(id, form) {
    return request.put(`${BASE_PATH}/${id}`, form);
  }

  /** 应用详情 */
  @loading({ delay: 0 })
  async detail(id) {
    return request.get(`${BASE_PATH}/${id}`);
  }

  /** 删除应用 */
  @loading()
  async del(id) {
    return request.delete(`${BASE_PATH}/${id}`);
  }

  /** 分页获取应用列表 */
  @loading({ delay: 0 })
  async getPageList(params) {
    return request.get(`${BASE_PATH}`, { params: params });
  }

  @loading({ delay: 0 })
  async getList() {
    return request.get(`${BASE_PATH}`);
  }

  @loading({ delay: 0 })
  async getAll() {
    const result = await request.get(`${BASE_PATH}`);
    return result.code === BizCode.OK
      ? result.data.map((r) => {
          return { label: r.name, value: r.appId };
        })
      : [];
  }

  @loading({ delay: 0 })
  async getResources(appId) {
    const result = await request.get(`${BASE_PATH}/${appId}/resource`);
    return result.code === BizCode.OK
      ? result.data.map((r) => {
          return { id:r.id,label: r.name, value: r.resource };
        })
      : [];
  }
  @loading({ delay: 0 })
  async getResourceList(appId) {
    const result = await request.get(`${BASE_PATH}/${appId}/resource`);
    return result;
  }

  @loading({ delay: 0 })
  async updateResource(appId,form) {
    return request.put(`${BASE_PATH}/updateResource/${appId}`, form);
  }

  // 模板信息
  @loading({ delay: 0 })
  async getTemplateList(params) {
    return request.get(`${BASE_PATH}/page`, { params: params });
  }

  //基本点 + 虚拟点 + 报警点
  // 新增
  @loading()
  async pointsAdd(form) {
    return request.post(`/gatewayTemplate`,form);
  }
  // 编辑  网关模板id
  @loading()
  async pointsEdit(id,form) {
    return request.put(`/gatewayTemplate/${id}`,form);
  }
  // 删除
  @loading()
  async pointsDelete(id) {
    return request.delete(`/gatewayTemplate/${id}`);
  }
  // 详情
  @loading()
  async pointsDetails(id) {
    return request.get(`/gatewayTemplate/${id}`);
  }
  //分页模板列表
  @loading()
  async gatewayTemplateList(params) {
    return request.get(`/gatewayTemplate/page`,{ params: params });
  }
}

export default new Api();
